import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import VueScreen from 'vue-screen';

Vue.config.productionTip = false
Vue.use(VueScreen, 'tailwind');
new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
