export default [{
  question: 'What is a Certified Professional Coach?',
  answer: `<span>
        A Certified Professional Coach is a partner who can help you identify and uncover what keeps you from showing up as the best version of yourself more consistently. It is a one-on-one partnership that provides a supportive and non-judgmental space to help you create what you want for life by building a Life Roadmap that will guide your every step. This roadmap will also help you break negative habits, acquire new skills and take action to achieve your maximum human potential!
    </span>`
},{
  question: 'What types of people have Coaches?',
  answer: `<span>
    People from all walks of life partner with Coaches to help attain their goals. Coaches work with people in all areas of their life including relationships, career, finance, health and more. Working with a personal Coach is not limited to any type of person, but is best for those who want to take initiative in their own life. People who partner with a Coach are committed to a more fulfilled life and are willing to do the work needed to get there.
  </span>`
},{
  question: 'How will coaching help?',
  answer: `<span>
    The results will vary depending on what brings you to a Coach. If you get a Coach to help you out professionally, coaching can help you make your current job more gratifying, gain better focus on managing your work, and market yourself more effectively. If your reasons are personal, you can partner with a coach to help you improve your relationships, resolve ongoing negative behaviors, improve your overall health, and grow with kindness and love. With life coaching you can live a more balanced and enhanced life, and make your dreams come true!
  </span>`
},{
  question: 'What happens in a Life Coaching Session?',
  answer: `<span>
    Each session is tailored to the client and will vary based on individual needs. In the beginning, we will discuss what you are committed to accomplishing through our work together and we will begin to create a plan to achieve your goals. Periodically, I will give you assignments to help direct your progress. We will work together to acknowledge obstacles, explore alternate perspectives and celebrate successes. With the conclusion of each session, we will revisit your goals and evaluate the next steps that are needed to achieve these results.
  </span>`
},{
  question: 'Do I really need a personal Coach, can\'t I just do it by myself?',
  answer: `<span>
    Coaching can take you above and beyond your own personal limitations. More often than not, people try to achieve their greatest potential, but don’t even realize what stands in their way. A personal Coach can offer an unbiased and objective perspective, and can help you realize your patterns and habits. Most people experience that they are more efficient when working with a Professional Life Coach and that this provides the opportunity for them to better realize their potential. Life coaching can make your dreams attainable and will give you support and encouragement along the way.
  </span>`
},{
  question: 'How is coaching different from therapy?',
  answer: `<span>
    While the life coaching I provide can be therapeutic, it is not therapy. Coaching is distinct from traditional therapy because it is not designed to address psychological issues. While emotional issues may arise, the primary focus of coaching is to help you identify your goals, achieve them, and develop constructive habits and skills. There is little emphasis placed on the past with coaching, but instead the focus is on creating what you want now and in the future.
  </span>`
},{
  question: 'How long do people generally work with a Coach?',
  answer: `<span>
    To experience the effectiveness of a life coaching program, I suggest clients make an initial 8-session commitment. At the end of the 8 sessions, we will evaluate progress and discuss options for continued coaching.
  </span>`
},{
  question: 'Is personal coaching confidential?',
  answer: `<span>
    What we discuss will be held in confidence and not shared with others. However, our discussions are not privileged for legal purposes. The privilege of confidentiality that exists for the clergy, therapists and attorneys is not granted to coaching professionals. That said, coaching is a confidential process, and your personal information will always be protected.
  </span>`
},{
  question: 'What is your cancellation policy?',
  answer: `<span>
    I require a 24 hour notice to cancel all sessions. Please be sure to contact me if you will not be able to make your scheduled appointment.
  </span>`
}
]