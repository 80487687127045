<template>
  <div>
    <splash></splash>
    <div class="center-container mt-6 bg-gray-200 md:rounded-lg md:shadow-lg py-16">
      <div class="text-hpp-green-dark text-center text-2xl font-bold pb-6">How would your life change if you could tap into your full human potential?</div>
      What if you could build a Life Roadmap to guide your every step?
      As a Certified Human Potential Coach (CHPC) my goal is to partner with you so you can show up more consistently as the best version of yourself. I provide support and guidance for transforming the issues that stand in the way of your achieving your goals by focusing on your core values. Whether you want coaching to achieve a specific outcome or wish to enhance all areas of your life, I can help you get there faster.
      If you're committed to creating a more fulfilling and meaningful life, I look forward to working with you.
      <div class="mt-4 text-xl text-center font-bold text-hpp-green-light">Contact me today for a FREE 30-minute CONSULTATION and start your journey toward achieving your dreams and living a life you love!</div>
      <div class="text-center">
        <button @click="$router.push('contact')" class="mt-6 bg-gray-200 p-2 px-12 rounded-xl text-xl transition hover:shadow-lg bg-hpp-green-dark hover:bg-hpp-green-light text-white">
          Get Started
        </button>
      </div>
    </div>

    <div class="bg-gray-100 mb-8 mt-12 py-4">
      <div class="center-container text-center">
        <div class="text-lg mb-4">
          <span class="font-bold text-hpp-green-light">Learn more about me</span> by checking out my podcast on how to be the best version of yourself!
        </div>
        <iframe
            src="https://anchor.fm/jim-zelem/embed/episodes/How-To-Be-The-Best-Version-of-Yourself-e17ojh4"
            height="102px"
            :width="$screen.breakpoint === 'xs' ? '300px' : '500px'"
            frameborder="0"
            scrolling="no"
            class="mx-auto"
        />

      </div>
    </div>


    <div class="center-container my-8 flex">
      <img src="imgs/human_potential_coach.jpg" style="width: 172px; height: 200px;" class="hidden md:inline mt-16">
      <div class="ml-8">
        <div class="text-hpp-green-dark text-center text-2xl py-4 font-bold">About Me</div>
        My life goal is to continue building my personal development muscles, so I can be the best version of myself for my family, my friends, my clients and everyone I meet. My purpose in this stage of my life is to make a positive difference in people’s lives because they deserve it.

        I began my career as a high school teacher and football coach. After earning my Master's Degree, I held multiple executive positions in the high-tech industry including my last position as the VP of Sales Excellence. I was certified as a Human Potential Coach from the Human Potential Institute the summer of 2020.

        I live in Rye, NH and can't get enough of the cold, hot, intermittent fasting, clean ketogenic lifestyle, heart coherence breathwork, Wim Hof breathing, meditation, bio-hacking, exercise and writing poems and reading self-help books.
        <br><br>
        With gratitude,
        <br>
        Ed
        <div class="mt-4 md:hidden">
          <img src="imgs/human_potential_coach.jpg" class="mx-auto">
        </div>
      </div>
    </div>

    <div class="center-container">
      <img src="imgs/Sales Coach.jpg" class="mx-auto">
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

import Splash from "@/views/Home/Splash";
export default {
  name: 'Home',
  components: {Splash},
}
</script>

<style>

</style>