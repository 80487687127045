export default [
  {
    reviewer: 'Jim',
    tag: 'Before anyone can have sound goals in life, they must establish strong values and clarify what they stand for. Through Ed’s Life Roadmap process, he has led me through that journey and helped me create that life compass.',
    content: false
  },
  {
    reviewer: 'Pierre - Life Explorer',
    tag: `The Ed life coach experience to me was a meaningful, results-driven journey with a beginning and end based on 'what I needed of myself'.`,
    content: `
      <span>
      My daily life revolved around meeting expectations of what the world wanted of me. From the time I woke up until the time I went to bed, the structure of world requirements surrounded me. Though, I did not have the ability to answer the question ‘what do I want from myself?’ Once I was ready to answer that question, then I sought Ed’s guidance as a life coach.
      <br><br>
      The question was only the beginning of a journey that Ed helped me navigate. As a life coach, Ed provided the tools to define ‘what I want from myself’. He offered me a journey of self-exploration that was insightful, well-thought out, supportive and, most importantly, at the pace I chose. The attractiveness of Ed’s approach to life coaching is that he offered me the tools to critically evaluate what I thought is really important to me. Then he offered a supportive approach to cross-examine myself, my system of thinking and behaving to illustrate where the two may conflict. The journey was one of significant self-realization in a context of honesty to oneself, about oneself.
      <br><br>
      The Ed life coach experience to me was a meaningful, results-driven journey with a beginning and end based on 'what I needed of myself'. It can apply to my life as a mission of deeper self-fulfillment as I return to Ed to embark on new journeys in life. The only question that remains is how hard am I willing to work to achieve what I want from myself?
      <br><br>
      I suggest, be unafraid, be kind to yourself and others, and go for it.
      </span>
    `,
  },
  {
    reviewer: 'Greg',
    tag: 'With his Life Roadmap concept, Ed brings tremendous commitment, energy and humor to this work with clients.  He\'s dedicated to helping clients find new perspectives and applying life \'hacks\' in a really positive way.',
    content: false
  },
  {
    reviewer: 'Rich',
    tag: 'He helps me hold myself accountable for areas I want to improve in my career and personal life.',
    content: `<span>
      Working with Ed Strenk on my Life Roadmap has been great!  It’s always a welcome break in my hectic work and family life each week.  He helps me hold myself accountable for areas I want to improve in my career and personal life.  Maybe best of all, Ed is a light-hearted person and always good for a needed laugh making our meetings fun.
    </span>`
  },
  {
    reviewer: 'Pam',
    tag: 'I am in a great place today, and feel that a lot of my success was in part due to the coaching I received from Ed.  He helped me find my way to the other side…  From role playing conversations so I could confidently approach folks I needed to talk to, had me set some weekly goals to achieve, and offered suggestions to improve sleep quality, eat better & healthier, all things that help improve your life and your being',
    content: `<span>
      It is an honor and a privilege to work with Ed Strenk.  Ed coached me through a difficult time of transition as former bosses were leaving, a new management team coming in, an overload of responsibilities, training, and adjustment.  Stress can feel overwhelming and leak into your family life, relationships, and health.  Ed was the perfect sounding board.  He listened and heard me.  Ed embraces and shares everything he has learned to improve the mind, body, and spirit.  
      <br><br>
      Ed diffused the chaos, first with breathing exercises to calm, and then listened with an attentive ear and open heart.  The discovery is some of the most eye opening moments – when you start to see what is really at the center of the issue causing you distress, ‘where’ in your body you are holding the tension, and then starting to see how these feelings/ walls, affect your happiness, health, and ability to move on…  
      <br><br>
      I started to notice that after each session with Ed, that I felt more calm, more focused, more confident, more committed to “me”.  To see Ed’s commitment to improving health, longevity, positivity, and happiness of the Universe – can at times make you chuckle…  He truly embodies this and seeks to help everyone find the joy in being the best version of themselves…   
      <br><br>
      I am in a great place today, and feel that a lot of my success was in part due to the coaching I received from Ed.  He helped me find my way to the other side…  From role playing conversations so I could confidently approach folks I needed to talk to, had me set some weekly goals to achieve, and offered suggestions to improve sleep quality, eat better & healthier, all things that help improve your life and your being…  
      <br><br>
      Life is full of stress, noise, clutter, and confusion… We all could use a little life coaching to help us find a brighter path to happiness... Don't put it off. May I suggest "mentor extraordinaire," Ed Strenk, to help you navigate your journey to reach higher levels of personal happiness and achievement.
    </span>`
  },
  {
    reviewer: 'LG',
    tag: 'My experience with Human Potential Pathways has transformed my experiences and my outlook on life. Together, Ed and I created a Life Roadmap that allowed me to assess my values and pinpoint my true desires for my life.',
    content: 'My experience with Human Potential Pathways has transformed my experiences and my outlook on life. Together, Ed and I created a Life Roadmap that allowed me to assess my values and pinpoint my true desires for my life. Through this experience I was able to manifest a healthy, loving relationship that fulfills each of my values, I became more financially secure, and I have a newfound focus on my health.'
  },
  {
    reviewer: 'Dave',
    tag: `Ed's unique and effective Life Roadmap helped me map out what it is I truly value in life.  
      He's a compassionate listener and always seems to have the right words to keep me moving in a positive direction.
      Whether it's professional or personal, Ed serves as a beacon for the right direction.  After our sessions,
      I'm calmer and more ready to take on my goals.  Thank you, Ed!`,
    content: false
  },
  {
    reviewer: 'Greg V.',
    tag: 'I\'ve been working with Ed at Human Potential Pathways for the better part of a year and every time we talk I\'m surprised by what we uncover and always inspired to become a more complete person.  Ed and I have been primarily working on my values and how they tie back to my overarching goals in life but quite often I need and ask for coaching in other directions such as family life, corporate life, and my growing small business.  As a coach Ed has been fantastic to work with allowing me to dive deep into topics I\'d like to discuss and has helped me understand my needs and a path forward to achieve them.  I\'d highly recommend Ed as coach both personally and professionally!',
    content: false
  },
  {
    reviewer: 'Erika',
    tag: 'Working with Ed has been an incredible journey. He takes time to understand my life goals and aspirations, and pushes me to create healthy habits and to choose strategies to make them attainable.',
    content: `<span>
      Working with Ed has been an incredible journey. He takes time to understand my life goals and aspirations, and pushes me to create healthy habits and to choose strategies to make them attainable. He puts time and care into the coaching process, and it is clear that he has a personalized plan to get each of his clients to reach their fullest potential. I always look forward to my sessions with Ed, and am excited to continue growing as a result of our partnership!
    </span>`
  },
  {
    reviewer: 'Beatriz',
    tag: 'Partnering with Ed as my coach has changed my life for the better. Whether you\'re someone looking for some clarity or want to invest in improving your life, Ed can help. Strategies to break negative habits, realistic goals to improve different areas of life, viewing relevant articles on life improvement skills, and creating a Life Roadmap are just some of the resources available during this journey.',
    content: `<span>
      Partnering with Ed as my coach has changed my life for the better. Whether you're someone looking for some clarity or want to invest in improving your life, Ed can help. Strategies to break negative habits, realistic goals to improve different areas of life, viewing relevant articles on life improvement skills, and creating a Life Roadmap are just some of the resources available during this journey. 
      <br><br>
      As part of its mission and vision, Human Potential Pathways (HPP) strives to help others through a supportive environment to be the best version of themselves. I am not the same person I was before starting this journey. HPP clearly and consistently meets that standard, and in so doing has become a valued addition to the online world of coaching. I feel privileged to have been partnering with Ed for almost a year, and look forward to being a member of the HPP community for years to come. Ed, you have changed my life! I am forever grateful for you
    </span>`
  },
  {
    reviewer: 'Carla',
    tag: 'With Ed\'s help I have a reliable and flexible roadmap that will help me reach my goal while considering life’s challenges along the way.',
    content: `<span>
      Ed is an amazing life coach. He is professional, empathic, and supportive. He helped guide me towards the path that was right for me by challenging my assumptions and habits. This made me really think about what I was doing and reexamine my life goals. Ed has a masterful knack of being a thought provoker and cheerleader.
      <br><br>
      With Ed's help I have a reliable and flexible roadmap that will help me reach my goal while considering life’s challenges along the way. If you’re looking for someone to help you refocus and create a mission statement for your life, I highly recommend working with him.
    </span>`
  },
  {
    reviewer: 'Steve',
    tag: 'As I retired, I was looking to refocus my goals in life and make a successful transition through all the related changes.  Ed’s Life Roadmap approach, passion for what he does and empathic listening, helped me: to  clarify who I wanted to be and why, to lay out a plan to take it on, and to hold myself accountable for doing what I set out to do.',
    content: false
  },
  {
    reviewer: 'Nicola',
    tag: 'Ed helped me change my life for the better in so many ways. The Life Roadmap I and Ed created together\n' +
      'allowed me to rediscover my core values and approach life with a positive mindset. His never-ending\n' +
      'support pushed me to achieve my goals and be the person I am today.',
    content: false
  },
  {
    reviewer: 'Sean',
    tag: 'Thanks to Ed and his work, I feel like I actually have a clear vision for how I want my life to go, as compared to the emptiness that was there before.',
    content: `<span>
    I started my work with Ed after I lost my job, my home, and my long-term relationship. It was very tough at first, but he encouraged me to find positives every day, and to look ahead at what a bright future I can carve out for myself. 
    <br><br>
    The breathing techniques in particular have been so beneficial to helping me when I’m in the midst of an anxiety attack. The aphorisms and calming nature of the breathing have helped me through so very difficult times.
    <br><br>
    While life still isn’t where I want it to be, I feel confident on my climb, and I know I am finally on the right trail. I have career, creative, and personal goals that I have set for myself, and I feel motivated to get up and tackle them each day. Thanks to Ed and his work, I feel like I actually have a clear vision for how I want my life to go, as compared to the emptiness that was there before.
    </span>`
  }, {
    reviewer: 'Patrick',
    tag: 'The actionable steps we took had a significant impact on my overall mental and physical health.',
    content: `<span>
        Prior to working with Ed, I knew that I wanted a change but I didn't know how to ask or where to start.  And as we started, I had an impression of the aspect of myself I wanted to improve.
        However, in developing my life roadmap with Ed, I learned more about myself than I expected and identified the real personal change I wanted.
        <br><br>
        The actionable steps we took had a significant impact on my overall mental and physical health.
        Ed has a unique ability to make you feel comfortable throughout the process, and get you to try new things in a fun and supportive way.
        I highly recommend working with him.  You will see personal improvement.
    </span>`
  },
  {
    reviewer: 'Mark',
    tag: `You’ve been amazing to work with. This has been the hardest year of my life. Everything that is going on surely would cause me to gain weight further, but somehow, with consistent focus upon my health that you have provided me, I’m 60 plus pounds down.`,
    content: false
  },
  // {
  //   reviewer: 'Reviewer',
  //   tag: 'tag',
  //   content: `<span>
  //
  //   </span>`
  // },
  ]