<template>
  <div>
    <div class="h-80 w-full contact-header">
      <div class="w-full h-80 text-center" style="background-color: rgba(0,0,0,0.5)">
        <div class="pt-12 sm:pt-20 text-5xl text-white">Be the<br>
          <span class="text-hpp-green-light font-bold">best version of yourself</span>
        </div>
        <div class="text-xl text-white mt-4">
          Contact me to find out how.<br>
          Get started with a FREE consultation!
        </div>
      </div>
    </div>

    <div class="center-container my-8">
      <div class="text-4xl my-4">Get In <span class="font-bold text-hpp-green-light">Contact</span></div>

      <div class="sm:flex sm:flex-row">
        <div class="sm:w-1/2 text-xl px-6">
          Human Potential Pathways can lead you through your own Life Roadmap to achieve your goals.
          <br><br>
          Together, we can identify what it is that <b>you</b> value most from life, identify your roadblocks, and forge the path towards a better you!
          <img src="imgs/human_potential_coach.jpg" class="mx-auto mt-4">
        </div>
        <div class="sm:w-1/2 mt-16 sm:mt-0 px-6 text-center bg-gray-200 p-4 rounded-lg">
          <div class="font-bold text-xl mb-8">Reach Me on Social Media</div>
          <div class="flex content-center justify-center transform scale-75 md:scale-90 lg:scale-100">
            <div
                v-for="s in socialMedia"
                :key="s.channel"
            >
              <a
                :class="[s.channel.toLocaleLowerCase()+'-gradient']"
                class="media-button h-22 w-22 rounded-full flex content-center cursor-pointer justify-center p-4 mx-3 shadow-lg"
                target="_blank"
                :href="s.link"
              >
                <s-icon
                  size="32"
                  color="white"
                  :ico="s.channel === 'TikTok' ? 'bx-bxl-tiktok' : 'mdi-'+s.channel.toLocaleLowerCase()"
                />
              </a>
              <div class="h-1 w-20 border-gray-400 border-t-2 mx-auto mt-6 mb-4"/>
              {{s.channel}}
            </div>
          </div>
          <div class="font-bold text-lg mt-8 mb-4">Reach Out by Email</div>
          <a target="_blank" href="mailto:edstrenk@gmail.com">
            <div class="p-2 rounded-full bg-hpp-green-light text-white hover:bg-hpp-green-dark hover:shadow-xl transition">Email Me</div>
          </a>
          <div class="text-sm mt-1">edstrenk@gmail.com</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import socialMedia from "@/assets/socialMedia";
import SIcon from "@/components/SIcon";

export default {
  name: "Contact",
  components: {SIcon},
  data: ()=>({
    socialMedia
  })
}
</script>

<style scoped>
  .contact-header{
    background-image: url("~@/assets/ct_sky.jpg");
    background-size: cover;
    background-repeat: no-repeat;
  }

  .media-button{
    @apply transition duration-500 ease-in-out transform hover:translate-y-1 hover:scale-110 hover:shadow-xl;
  }
</style>