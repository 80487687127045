export default [{
  channel: 'Facebook',
  link: 'https://www.facebook.com/edstrenk/?view_public_for=114399257056045'
},{
  channel: 'YouTube',
  link: 'https://www.youtube.com/user/edstrenk'
},{
  channel: 'LinkedIn',
  link: 'https://www.linkedin.com/in/estrenk/'
},{
  channel: 'Instagram',
  link: 'https://www.instagram.com/edstrenk/'
},{
  channel: 'TikTok',
  link: 'https://www.tiktok.com/@edstrenk?'
}]