<template>
  <div id="app">
    <mobile-nav v-show="showNav" :show="showNav" :close="()=>showNav=false"></mobile-nav>
    <div class="fixed shadow-lg inset-x-0 p-2 bg-white z-10">
      <div class="container mx-auto max-w-6xl flex justify-between">
        <div class="lg:hidden p-2 flex">
          <div @click="showNav=true">
          <s-icon ico="mdi-menu" size="26"></s-icon>
          </div>
          <button
              class="nav-button flex"
              style="margin-top: -6px;"
              @click="setMusic(playing ? 'pause' : 'play')">
            <s-icon size="22" ico="mdi-pause" v-show="playing"></s-icon>
            <s-icon size="22" ico="mdi-play" v-show="!playing"></s-icon>
            Music
          </button>
        </div>

        <router-link to="/">
          <div class="text-xl font-bold mt-1">Human Potential Pathways</div>
        </router-link>
        <div class="hidden lg:flex">
          <router-link to="services">
            <button class="nav-button">Services</button>
          </router-link>
          <router-link to="testimonials">
            <button class="nav-button">Testimonials</button>
          </router-link>
          <router-link to="faq">
            <button class="nav-button">FAQ</button>
          </router-link>
          <router-link to="resources">
            <button class="nav-button">Resources</button>
          </router-link>
          <router-link to="tips">
            <button class="nav-button">Tips & Tricks</button>
          </router-link>
          <router-link to="contact">
            <button class="nav-button">Contact</button>
          </router-link>
          <button
              class="nav-button flex"
              @click="setMusic(playing ? 'pause' : 'play')">
            <s-icon size="22" ico="mdi-pause" v-show="playing"></s-icon>
            <s-icon size="22" ico="mdi-play" v-show="!playing"></s-icon>
            Music
          </button>
        </div>
      </div>
    </div>
    <div class="pt-14">
      <router-view/>
      <div class="container flex justify-center p-4">
        <div class="text-center">
          Relax to our wonderful background music<br>
          <span class="font-bold">"Four hands one heart"</span> - © 2022 Matt Bishop Music

          <audio controls autoplay id="four-hands" muted="muted" class="ml-8">
            <source src="https://liferoadmap.design/Four-hands-one-heart.mp3" type="audio/mpeg">
            Your browser does not support the audio element.
          </audio>
        </div>

      </div>

    </div>
    <hp-footer></hp-footer>
  </div>
</template>

<script>
import HpFooter from "@/components/HpFooter";
import MobileNav from "@/components/MobileNav";
import SIcon from "@/components/SIcon";
// let playingAudio = false;
export default {
  components: {SIcon, MobileNav, HpFooter},
  data: ()=>({
    showNav: false,
    playing: false,
  }),
  methods: {
    setMusic(set){
      var x = document.getElementById("four-hands");
      if(set==='play'){
        x.play(); this.$data.playing = true;
      }else{
        x.pause(); this.$data.playing = false;
      }
    }
  },
  mounted() {
    var x = document.getElementById("four-hands");
    x.addEventListener('play', () => {
      this.$data.playing = true;
    });
    x.addEventListener('pause', x.onpause = () => {
      this.$data.playing = false;
    });
  }
}
</script>

<style>
#app {
  font-family: 'Gowun Dodum', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

.nav-button{
  @apply mx-1 p-2 px-4 rounded-lg hover:bg-hpp-green-dark hover:text-white transition;
}

/*Text*/
.cursive{
  font-family: 'Parisienne', cursive;
}

/*Layout*/
.center-container{
  @apply container mx-auto max-w-6xl p-4 mt-2;
}

/*Gradients*/
.hpp-gradient-green{
  @apply bg-gradient-to-bl from-hpp-green-light to-hpp-green-dark;
}
.facebook-gradient{
  @apply bg-gradient-to-tr from-facebook-primary-dark to-facebook-primary;
}
.instagram-gradient{
  background: radial-gradient(circle farthest-corner at 35% 90%, #fec564, transparent 50%), radial-gradient(circle farthest-corner at 0 140%, #fec564, transparent 50%), radial-gradient(ellipse farthest-corner at 0 -25%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 20% -50%, #5258cf, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 0, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 60% -20%, #893dc2, transparent 50%), radial-gradient(ellipse farthest-corner at 100% 100%, #d9317a, transparent), linear-gradient(#6559ca, #bc318f 30%, #e33f5f 50%, #f77638 70%, #fec66d 100%);
}
.youtube-gradient{
  @apply bg-gradient-to-tr from-youtube-primary-dark to-youtube-primary;
}
.linkedin-gradient{
  @apply bg-gradient-to-tr from-facebook-primary-dark to-facebook-primary;
}
.tiktok-gradient{
  background: linear-gradient(to top right, #25F4EE, #FE2C55);
}

/*Animations*/
.fade-in {
  -webkit-animation: fadein 2s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 2s; /* Firefox < 16 */
  -ms-animation: fadein 2s; /* Internet Explorer */
  -o-animation: fadein 2s; /* Opera < 12.1 */
  animation: fadein 2s;
}

.fade-in-delay-3 {
  -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
  -moz-animation: fadein 3s; /* Firefox < 16 */
  -ms-animation: fadein 3s; /* Internet Explorer */
  -o-animation: fadein 3s; /* Opera < 12.1 */
  animation: fadein 3s;
}

@keyframes fadein { from { opacity: 0; } to   { opacity: 1; } }
/* Firefox < 16 */
@-moz-keyframes fadein { from { opacity: 0; } to   { opacity: 1; } }
/* Safari, Chrome and Opera > 12.1 */
@-webkit-keyframes fadein { from { opacity: 0; } to   { opacity: 1; } }
/* Internet Explorer */
@-ms-keyframes fadein { from { opacity: 0; } to   { opacity: 1; } }

</style>