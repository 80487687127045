export default [{
  category: 'Anxiety',
  links: [{
    caption: 'Generalized Anxiety Disorder',
    url: 'http://www.medicinenet.com/anxiety/article.htm'
  },{
    caption: 'Living With Anxiety',
    url: 'http://www.livingwithanxiety.com/'
  },{
    caption: 'Natural Remedies for Anxiety',
    url: 'http://altmedicine.about.com/cs/conditionsatod/a/Anxiety.htm'
  },{
    caption: 'Overcoming Anxiety',
    url: 'https://www.unstuck.com/anxiety/'
  },{
    caption: 'What is Anxiety?',
    url: 'http://www.medicalnewstoday.com/info/anxiety/'
  }]
},{
  category: 'Balance',
  links: [{
    caption: '5 Tips for Better Work/Life Balance',
    url: 'http://www.webmd.com/balance/guide/5-strategies-for-life-balance'
  },{
    caption: 'Creating Life Balance to Achieve Success',
    url: 'https://www.forbes.com/sites/louisefron/2014/08/28/creating-life-balance-to-achieve-success/#6f039bad1892'
  },{
    caption: 'How to Create Balance',
    url: 'https://tinybuddha.com/blog/9-tips-to-create-a-balanced-life//'
  },{
    caption: 'Life is a Balancing Act - The Book',
    url: 'http://www.lifeisabalancingact.com/'
  },{
    caption: 'MindTools - The Wheel of Life',
    url: 'https://www.mindtools.com/pages/article/newHTE_93.htm'
  },{
    caption: 'Spiritual Balance',
    url: 'http://www.wholesomebalance.com/Spiritual_Balance.html'
  }]
},{
  category: 'Career and Job Searching',
  links: [{
    caption: 'Career Guide - Careers.org',
    url: 'www.google.com'
  },{
    caption: 'Find Jobs - Build a Better Career',
    url: 'http://www.careers.org/'
  },{
    caption: 'Job Search and Career Advice',
    url: 'https://www.monster.com/'
  },{
    caption: 'Job Hunting - News, Trends, and More',
    url: 'http://www.truecareers.com/'
  },{
    caption: 'One Search.  All Jobs',
    url: 'https://jobs.livecareer.com/'
  },{
    caption: 'The Largest Job Search',
    url: 'http://www.careerbuilder.com/'
  }]
},{
  category: 'Communicating Skills',
  links: [{
    caption: '10 Keys to Becoming...',
    url: 'http://www.dumblittleman.com/2010/08/10-keys-to-be-becoming-better.html'
  },{
    caption: 'Communication - Livestrong',
    url: 'https://www.livestrong.com/article/145032-ways-to-improve-verbal-communication/'
  },{
    caption: 'Master Communication Skills & Confidence',
    url: 'http://www.littlethingsmatter.com/blog/2010/11/30/10-verbal-communication-skills-worth-mastering/'
  },{
    caption: 'Mind Tools - Improving Communication',
    url: 'https://www.mindtools.com/page8.html'
  }]
},{
  category: 'Conflict Resolution',
  links: [{
    caption: 'Conflict Resolution Information Source',
    url: 'http://www.crinfo.org/'
  },{
    caption: 'Conflict Resolution Strategies',
    url: 'http://hubpages.com/hub/Conflict-Management-Insight-and-Skills'
  },{
    caption: 'Conflict Management Strategies and Styles',
    url: 'http://home.snu.edu/~HCULBERT/conflict.htm'
  },{
    caption: 'Helpguide - Conflict Resolution Skills',
    url: 'https://www.helpguide.org/articles/relationships-communication/conflict-resolution-skills.htm'
  },{
    caption: 'Mindtools - Conflict resolution',
    url: 'https://www.mindtools.com/pages/article/newLDR_81.htm'
  }]
},{
  category: 'Couples',
  links: [{
    caption: 'How to Grow as a Couple',
    url: 'https://tinybuddha.com/blog/when-relationships-change-growing-together-not-apart/'
  },{
    caption: 'How to Improve Your Relationship',
    url: 'http://www.netdoctor.co.uk/sex_relationships/facts/improverelationship.htm'
  },{
    caption: 'Improving Relationships',
    url: 'http://www.beyondintractability.org/essay/personal-relationships'
  },{
    caption: 'Seven Steps to Improving Relationships',
    url: 'http://www.beliefnet.com/Faiths/Judaism/2008/09/Seven-Steps-to-Improving-Relationships.aspx'
  }]
},{
  category: 'Creativity',
  links: [{
    caption: '10 Mental Blocks to Creative Thinking',
    url: 'http://www.copyblogger.com/mental-blocks-creative-thinking/'
  },{
    caption: '10 Steps for Boosting Your Creativity',
    url: 'https://www.creativejeffrey.com/creative/creative.php'
  },{
    caption: 'Using Your Creativity to Find Satisfaction',
    url: 'http://ezinearticles.com/?Using-Your-Creativity-to-Find-Satisfaction-in-Life&id=1096959'
  },{
    caption: 'Creativity - SUCCESS Magazine',
    url: 'http://www.success.com/article/1-on-1-how-to-inspire-creativity-in-your-team'
  },{
    caption: 'Creativity - Wikipedia',
    url: 'https://en.wikipedia.org/wiki/Creativity'
  }]
},{
  category: 'Depression',
  links: [{
    caption: 'Dealing with Depression',
    url: 'https://www.helpguide.org/articles/depression/coping-with-depression.htm'
  },{
    caption: 'Medline Plus - News/Information',
    url: 'https://www.nlm.nih.gov/medlineplus/depression.html'
  },{
    caption: 'National Institute of Mental Health - Depression',
    url: 'https://www.nimh.nih.gov/health/publications/depression/complete-index.shtml'
  },{
    caption: 'Helping Someone with Depression',
    url: 'https://www.helpguide.org/articles/depression/helping-someone-with-depression.htm'
  },{
    caption: 'Causes of Depression - WebMD',
    url: 'http://www.webmd.com/depression/guide/causes-depression'
  },{
    caption: 'What is Depression?',
    url: 'http://www.allaboutdepression.com/gen_01.html'
  }]
},{
  category: 'Family Mediation',
  links: [{
    caption: 'Family Mediation Council',
    url: 'http://www.familymediation.com/'
  },{
    caption: 'Juvenile Services',
    url: 'http://www.fmjs.org/'
  },{
    caption: 'NDRI - Handle Your Children Sibling Battles',
    url: 'http://ndri.com/news/handle_your_children_sibling_battles_by_mediation_training_and_understanding-289.html'
  },{
    caption: 'The Basics of Family Mediation',
    url: 'http://www.mccammongroup.com/services/family-law-mediation/basics-family-mediation/'
  },{
    caption: 'Teaching Skills to Parents',
    url: 'https://www.sciencedaily.com/releases/2007/05/070517063046.htm'
  },{
    caption: 'Why Kids Fight and How to Help',
    url: 'http://www.parenting-child-development.com/kids-fighting.html'
  }]
},{
  category: 'Getting Unstuck',
  links: [{
    caption: 'Letting Go - Anthony Robbins',
    url: 'https://www.tonyrobbins.com/mind-meaning/let-go-past/'
  },{
    caption: 'Feeling Stuck? Harvard Business School',
    url: 'http://hbswk.hbs.edu/item/5548.html'
  },{
    caption: 'How to Get Unstuck',
    url: 'http://ezinearticles.com/?How-To-Get-Unstuck-And-Live-Your-Best-Life&id=1061035'
  },{
    caption: 'Taming Your Turbulent Past',
    url: 'http://members.wizzards.net/~mlworden/turb/turb07.htm'
  },{
    caption: 'How to Get Unstuck from Depression',
    url: 'http://drhyman.com/blog/2010/08/30/how-to-get-unstuck-from-depression/'
  },{
    caption: `What Should You do When You're Stuck?`,
    url: 'http://www.personal-development.com/yasha/stuck-position.htm'
  }]
},{
  category: 'Health & Wellbeing',
  links: [{
    caption: 'Articles on Health and Wellbeing - Gizmag',
    url: 'http://www.gizmag.com/health/'
  },{
    caption: 'Healthy, Happy, and Prosperous',
    url: 'http://www.wellbeingspot.com/'
  },{
    caption: 'Health and Wellness - iVillage',
    url: 'https://www.today.com/health'
  },{
    caption: 'The Center for Health and Wellbeing',
    url: 'http://chwbonline.com/'
  }]
},{
  category: 'Intimacy',
  links: [{
    caption: 'Fear of Intimacy',
    url: 'https://www.psychologytoday.com/blog/the-freedom-change/201504/fear-intimacy-and-closeness-in-relationships'
  },{
    caption: 'Handling Intimacy',
    url: 'https://www.livestrong.com/intimacy/'
  },{
    caption: 'How to Build Intimacy in Your relationship',
    url: 'http://www.oprah.com/relationships/Building-Intimacy-Gender-Differences-in-Intimacy'
  },{
    caption: 'Sex and the Search for Intimacy - Fear of Intimacy',
    url: 'http://www.everystudent.com/features/search.html'
  },{
    caption: 'The Impact of Fear of Intimacy',
    url: 'http://www.familyresource.com/relationships/building-and-maintaining/the-impact-of-intimacy'
  },{
    caption: 'Intimate Relationship - Wikipedia',
    url: 'https://en.wikipedia.org/wiki/Intimate_relationship'
  }]
},{
  category: 'Loss & Grief',
  links: [{
    caption: 'The Cycle of Grief - BBC',
    url: 'https://www.bbc.co.uk/politics97/diana/cycle.html'
  },{
    caption: 'Coping with Grief and Loss',
    url: 'https://www.helpguide.org/articles/grief/coping-with-grief-and-loss.htm'
  },{
    caption: 'How to Move On',
    url: 'http://hubpages.com/hub/How-to-move-on-after-death-of-a-love-one_2'
  },{
    caption: 'The Psychology of Death',
    url: 'https://www.psychologytoday.com/blog/out-the-darkness/201402/the-psychology-death'
  }]
},{
  category: 'Motivation',
  links: [{
    caption: 'How Self-Motivated Are You?',
    url: 'https://www.mindtools.com/pages/article/newLDR_57.htm'
  },{
    caption: 'How to Motivate Yourself',
    url: 'http://www.pickthebrain.com/blog/how-to-motivate-yourself/'
  },{
    caption: 'Motivation Blogs',
    url: 'http://www.motivationalwellbeing.com/'
  },{
    caption: 'Motivation 123',
    url: 'http://www.motivation123.com/'
  },{
    caption: 'Self Motivation - How Can You Improve Yours?',
    url: 'http://www.more-selfesteem.com/self_motivation.htm'
  }]
},{
  category: 'Shame & Guilt',
  links: [{
    caption: 'The Difference Between Shame and Guilt',
    url: 'https://www.psychologytoday.com/blog/hide-and-seek/201703/whats-the-difference-between-guilt-and-shame'
  },{
    caption: 'Guilt & Shame',
    url: 'http://www.beyondintractability.org/essay/guilt_shame/'
  },{
    caption: 'Letting Go of Shame and Guilt',
    url: 'https://bradfordhealth.com/shame-and-guilt/'
  }]
},{
  category: 'Workplace Resolution',
  links: [{
    caption: '8 Strategies to Manage Workplace Conflict',
    url: 'http://www.businessknowhow.com/manage/resolveconflict.htm'
  },{
    caption: 'Articles on Conflict Resolution',
    url: 'http://www.abetterworkplace.com/?s=conflict+resolution'
  },{
    caption: 'Conflict Resolution Skills',
    url: 'http://stress.about.com/od/relationships/a/conflict_res.htm'
  },{
    caption: 'Conflict Resolution Activities',
    url: 'http://www.ehow.com/list_6113385_conflict-resolution-activities-workplace.html'
  },{
    caption: 'Mediating Conflict Resolution',
    url: 'https://www.thebalance.com/workplace-conflict-resolution-1918675'
  },{
    caption: 'Tips for Dealing With Workplace Conflict',
    url: 'https://www.allbusiness.com/tips-for-dealing-with-workplace-conflict-12260-1.html'
  }]
}]