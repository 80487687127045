<template>
  <div>
    <!-- MAIN TITLE -->
    <div class="text-white hpp-gradient p-8">
      <div class="container mx-auto max-w-6xl">
        <div class="text-4xl max-w-min pb-2 border-b-2">Services</div>
        <div class="text-gray-200 mt-2">
          As a Certified Human Potential Coach (CHPC), I partner with a wide range of clients.
          Through my unique Life Roadmap approach, your best self is closer than you think!
        </div>
      </div>
    </div>
    <!-- INTRO IMAGE AND TEXT -->
    <div class="container mx-auto max-w-6xl p-4 mt-2">
      <div class="flex mb-8">
        <img src="imgs/ed_portrait_1.jpg" class="rounded-l-lg max-h-48">
        <div class="bg-gray-100 flex-grow p-2 sm:p-4 pt-4 sm:pt-8 md:pt-10 rounded-r-xl text-center sm:text-lg md:text-xl">
          <div class="text-xl sm:text-2xl">Together, we'll find the very <b>best</b> version of yourself</div>
          <div class="cursive text-xl sm:text-2xl mt-6 flex justify-center">
            <s-icon ico="mdi-tilde" color="#E6E6E6" size="24" class="mx-6" style="transform: scaleX(2) skewX(-0.5rad)"/>
            Ed Strenk
            <s-icon ico="mdi-tilde" color="#E6E6E6" size="24" class="mx-4" style="transform: scaleX(2) skewX(-0.5rad)"/>
          </div>
        </div>
      </div>
    </div>

    <!-- CLIENT OFFERINGS -->
    <div class="container mx-auto max-w-6xl">
      <div class="text-lg px-4">
        Some of the many backgrounds my clients have include:
      </div>
      <!-- CLIENT OFFERINGS:  SMALL- SCREENS -->
      <div
          v-for="(a,i) in audiences"
          :key="i+'sm'"
          class="p-4 ml-6 mr-6 m-2 mx-auto sm:hidden flex flex-wrap content-start rounded-lg hpp-gradient"
      >
        <s-icon :ico="a.ico" :size="24" color="#EBEBEB"></s-icon>
        <div class="text-lg font-bold ml-4 text-white">{{a.label}}</div>
      </div>

      <!-- CLIENT OFFERINGS:  SMALL+ SCREENS -->
      <div class="hidden sm:block px-6">
        <div class="grid grid-cols-3 gap-4 mx-auto max-w-screen-md place-content-stretch p-2">
          <div
              v-for="(a,i) in audiences"
              :key="i"
              class="h-40 p-6 text-center rounded-lg"
          >
            <div class="max-w-min mx-auto rounded-full p-6 shadow-lg hpp-gradient">
              <s-icon :ico="a.ico" :size="48" color="#EBEBEB"></s-icon>
            </div>
            <div class="text-lg mt-4 font-bold">{{a.label}}</div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-gray-100 mt-8 py-4">
      <div class="center-container text-center">
        <div class="text-lg mb-4">
          <span class="font-bold text-hpp-green-light">Learn more about how it works</span> by checking out my podcast on how to be the best version of yourself!
        </div>
        <iframe
            src="https://anchor.fm/jim-zelem/embed/episodes/How-To-Be-The-Best-Version-of-Yourself-e17ojh4"
            height="102px"
            :width="$screen.breakpoint === 'xs' ? '300px' : '500px'"
            frameborder="0"
            scrolling="no"
            class="mx-auto"
        />

      </div>
    </div>

    <!-- THE LIFE PLAN -->
    <div class="bg-hpp-green-light">
      <div class="container mx-auto max-w-6xl px-4">
        <!-- TITLE -->
        <div class="rounded-t-lg text-center pt-12">
          <div class="text-4xl text-white">The Life Roadmap</div>
          <div class="pb-4 text-gray-200">
            Human Potential Pathways offers a unique and actionable plan to be the best version of yourself.
          </div>
        </div>

        <!-- STEPS -->
        <div>
          <div
              v-for="(v,i) in lifePlanSteps"
              :key="i"
              class="mt-6"
          >
            <div class="text-2xl font-bold text-white">
              <s-icon :ico="v.ico" size="24" class="float-left mt-1"/>
              <div class="float-left ml-8 float-none">{{v.title}}</div>
            </div>
            <div class="px-1 sm:px-8 text-gray-200">{{v.caption}}</div>
          </div>
        </div>

        <div class="text-center">
          <button @click="$router.push('contact')" class="my-6 bg-gray-200 p-2 px-12 rounded-xl text-lg transition hover:shadow-lg hover:bg-hpp-green-dark hover:text-white">
            Get Started
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SIcon from "@/components/SIcon";
export default {
  name: "Services",
  components: {SIcon},
  data: ()=>({
    audiences: [
      {ico: 'mdi-compass-rose', label: 'Executives'},
      {ico: 'mdi-lightbulb-on-outline', label: 'Entrepreneurs'},
      {ico: 'mdi-briefcase', label: 'Working Professionals'},
      {ico: 'mdi-chart-areaspline', label: 'Sales Professionals'},
      {ico: 'mdi-pencil', label: 'Students'},
      {ico: 'mdi-food-apple', label: 'Teachers'},
    ],
    lifePlanSteps: [{
      ico: 'mdi-hand-heart',
      title: 'Finding what you long for',
      caption: `We'll proceed through exercises designed to find what it is you long for in life.
        To accumulate wealth?
        Develop strong and meaningful relationships with others?
        Through our work together, we'll arrive at actionable goals.`
    },{
      ico: 'mdi-human-handsup',
      title: 'What do you value in life?',
      caption: `Do you know what your core values are?
        Many of my clients arrive to find that they don't fully know what it is they want from their lives.
        Through gentle guidance and value exploration, we start by finding what your best self values.`
    }, {
      ico: 'mdi-shoe-print',
      title: 'Paving your road to success',
      caption: `With your destination in mind, what are the steps to achieve your dreams?
        How can we remain accountable to our goals?  Together, we'll pave the path towards full and joyous living.`
    }, {
      ico: 'mdi-bottle-tonic-plus',
      title: 'Healthy mind, body, and soul',
      caption: `Every path has its roadblocks and stumbling points.  By targeting the things that hold us back, we can work
      to conquer fear and work towards healthy living.`
    }, {
      ico: 'mdi-compass',
      title: 'Staying on course',
      caption: `As my clients evolve to their best selves, we adjust and maintain our values and goals to not only get on the right path, but to stay on it.`
    }]
  })
}
</script>

<style scoped>
 .life-plan{
   background-image: url("../assets/ct_sky.jpg");
   background-position: center;
   background-size: cover;
 }

 .hpp-gradient{
   @apply bg-gradient-to-bl from-hpp-green-light to-hpp-green-dark;
 }
</style>