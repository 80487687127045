The top header with a full-width gradient background

<template>
  <div>
    <view-header title="Testimonials">
      Human Potential Pathway clients are finding the best version of themselves.  You don't have to just take my word for it - see what my customers are saying about their experience.
    </view-header>
    <div class="center-container">
      <div
        v-for="(t, i) in testimonials"
        :key="i"
        class="text-center mx-auto bg-gray-100 my-4 p-4 rounded-lg transition-all"
        :class="{'shadow-lg': t.showFull}"
      >
        <div>
          <div v-if="!t.showFull">{{t.tag}}</div>
          <div v-if="t.showFull" v-html="highlight(t.content, t.tag)" class="text-left"></div>
        </div>

        <div class="my-2 text-sm w-56 mx-auto cursor-pointer" v-if="t.content" @click="t.showFull=!t.showFull">
          <span class="italic">
            Read {{t.showFull ? 'less' : 'more'}} of {{t.reviewer}}'s experience
          </span>
        </div>
        <div class="h-1 w-32 bg-hpp-green-light mx-auto my-2"></div>
        <div class="cursive text-2xl">{{t.reviewer}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import testimonials from "@/assets/testimonials";
testimonials.forEach(t => t.showFull = false)
export default {
  name: "Testimonials",
  components: {ViewHeader},
  data: ()=>({
    testimonials
  }),
  methods: {
    highlight(full, tag){
      return full.replace(tag, '<b>'+tag+'</b>')
    }
  }
}
</script>

<style>

</style>