<template>
  <div>
    <!-- MAIN TITLE -->
    <div class="text-white hpp-gradient p-8">
      <div class="container mx-auto max-w-6xl">
        <div class="text-4xl max-w-min pb-2 border-b-2">Resources</div>
        <div class="text-gray-200 mt-2">
          Below you'll find some of my favorite resources and strategies that can be used to find your best self.
          <br><br>
          <div class="text-sm text-gray-300">
            *Note:  All links open a new page to an external resource
          </div>
        </div>
      </div>
    </div>

    <div class="center-container sm:p-8">
      <div class="grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-3 gap-4">
        <div
          v-for="(r, i) in resources"
          :key="i"
          class="bg-gray-100 rounded-lg p-2 px-4"
        >
          <div class="text-center text-2xl text-hpp-green-dark mt-4">
            {{r.category}}
          </div>
          <div class="h-1 w-24 mx-auto bg-gray-400 mt-4 mb-6"></div>
          <a
            :href="link.url"
            target="_blank"
            v-for="(link,j) in r.links"
            :key="j"
          >
            <div
              class="bg-hpp-green-light rounded-lg text-white p-2 px-4 my-1 transition hover:shadow-lg hover:bg-hpp-green-dark"
            >
              {{link.caption}}
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import resources from "@/assets/resources";
export default {
  name: "Resources",
  data: ()=>({
    resources
  })
}
</script>

<style scoped>
.hpp-gradient{
  @apply bg-gradient-to-bl from-hpp-green-light to-hpp-green-dark;
}
</style>