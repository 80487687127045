<template>
  <span
      :style="{
        'font-size': size+'px',
        'color': color ? color + ' !important' : null
      }"
  >
      <span
          class="iconify s-icon"
          :data-icon="icon"
          :icon="icon"
      />
  </span>

</template>

<script>
export default {
  name: "SIcon",
  props: {
    ico: {default: 'mdi-home'},
    size: {default: 16},
    color: {default: null}
  },
  computed: {
    icon(){
      let ico = this.ico.split(/-(.+)/)
      return `${ico[0]}:${ico[1]}`;
    }
  }
}
</script>

<style>
.s-icon{
  transition: color 250ms;
}
</style>