<template>
  <div>
    <view-header title="F.A.Q.">
      Have a question about coaching?  Below, you'll find answers to some of the most frequently asked questions.
    </view-header>
    <div class="center-container">
      <div
        v-for="(f,i) in faq"
        :key="i"
        class="mb-8"
      >
        <div class="text-xl text-center text-hpp-green-light">{{f.question}}</div>
        <div class="h-1 w-32 bg-hpp-green-light mx-auto my-2"></div>
        <div v-html="f.answer"></div>
      </div>
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import faq from "@/assets/faq";
export default {
  name: "Faq",
  components: {ViewHeader},
  data: ()=>({
    faq
  })
}
</script>

<style scoped>

</style>