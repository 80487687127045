<template>
  <div>
    <view-header title="Tips, Tricks, and Hacks">
      Find resources for some of my favorite practices for a healthy mind, body, and soul.
<!--      <div class="text-center mt-6">-->
<!--        See all my tips, tricks, and hacks on my YouTube channel!-->
<!--        <a class="flex content-center max-w-md justify-center px-4 mt-2 mx-auto cursor-pointer rounded-lg yt-gradient shadow hover:shadow-lg transition-all">-->
<!--          <div class="text-lg font-bold text-white mt-2 mr-4">Human Potential Pathways</div>-->
<!--          <s-icon size="48" ico="mdi-youtube" color="white"/>-->
<!--        </a>-->
<!--        <span class="text-xs">Click to visit my YouTube channel</span>-->
<!--      </div>-->
      <div
        class=" max-w-min mx-auto rounded-2xl text-center mt-8 p-6"
        style="background-color: rgba(0,0,0,0.1)"
      >
        <div class="text-xl mb-8">
          Find me on social media for tips on how to be the <i>best</i> version of yourself
        </div>
        <div class="flex content-center justify-center transform scale-75 sm:scale-100">
          <div
              v-for="s in socialMedia"
              :key="s.channel"

          >
            <a
                :class="[s.channel.toLocaleLowerCase()+'-gradient']"
                class="media-button h-22 w-22 rounded-full flex content-center cursor-pointer justify-center p-4 mx-3 shadow-lg"
              target="_blank"
                :href="s.link"
            >
              <s-icon :size="$screen.breakpoint === 'xs' ? 32:44" :ico="s.channel === 'TikTok' ? 'bx-bxl-tiktok' : 'mdi-'+s.channel.toLocaleLowerCase()"/>
            </a>
            <div class="h-1 w-20 border-gray-400 border-t-2 mx-auto mt-6 mb-4"/>
            {{s.channel}}
          </div>
        </div>
      </div>
    </view-header>
    <div class="center-container">
<!--      <div>-->
<!--        <div class="text-2xl text-hpp-green-dark">Products</div>-->
<!--        <div>-->

<!--        </div>-->
<!--      </div>-->

      <div>
<!--        <div class="text-2xl text-hpp-green-dark">Be Calm and Carry On</div>-->
        <div class="grid md:grid-cols-3 gap-4">
          <technique-block
            v-for="t in techniques"
            :key="t.title"
            :val="t"
          ></technique-block>
        </div>
      </div>
<!--      <div class="flex flex-row">-->
<!--        <img src="imgs/unsplah_gum.jpg" class="rounded-xl shadow">-->
<!--        <div class="p-2 text-lg">-->
<!--          Sugar-free gum that's actually good for you!  No aspartame!-->
<!--        </div>-->
<!--      </div>-->
    </div>
  </div>
</template>

<script>
import ViewHeader from "@/components/ViewHeader";
import SIcon from "@/components/SIcon";
import socialMedia from "@/assets/socialMedia";
import TechniqueBlock from "@/views/Tips/TechniqueBlock";

export default {
  name: "Tips",
  components: {TechniqueBlock, SIcon, ViewHeader},
  data: ()=>({
    socialMedia,
    techniques: [{
      title: 'Wim Hof - Just Breath',
      desc: 'Learn the breathing technique used by the renound Wim Hof. Breath your way to a healthier, calmer life.',
      links: [
        {title: 'Wim Hof Method', url: 'https://www.wimhofmethod.com/', src: 'wimhofmethod.com'},
        {title: 'Wim Hof Video 1', url: 'https://www.youtube.com/watch?v=wFj6TOYpQG8'},
        {title: 'Wim Hof Video 2', url: 'https://www.youtube.com/watch?v=0BNejY1e9ik'},
        {title: 'Wim Hof Video 3', url: 'https://www.youtube.com/watch?v=LU6Oi80n5J4', src: 'youtube.com'},
      ]
    }, {
      title: 'Wake your Mitochondria',
      desc: 'Wake up your power cells called mitochondria.  Feel more energy and boost your immune system.',
      links: [{title: 'Wake your Mitochondria', url: 'https://www.facebook.com/edstrenk/videos/140476831114954', src: 'facebook.com'}]
    }, {
      title: 'Meditation Apps for Happiness',
      desc: 'Meditation is clinically proven to provide many benefits - including stress reduction and improved blood pressure.  Stay on top of your meditation game with 8 apps to help you keep focused.',
      links: [{title: 'Meditation Apps', url: 'https://www.verywellmind.com/best-meditation-apps-4767322', src: 'verywellmind.com'}]
    }, {
      title: 'Why you should laugh more',
      desc: 'Did you know laughing can improve your immune system?  A good laugh can relieve stress and sooth tension, but long term effects do even more to improve our health.',
      links: [{title: 'Laugh More!', url: 'https://www.mayoclinic.org/healthy-lifestyle/stress-management/in-depth/stress-relief/art-20044456', src: 'mayoclinic.org'}]
    }, {
      title: 'Benefits of cold showers',
      desc: 'Cold water therapy - also called cold thermogenesis, or Deliberate Cold Exposure (DCE) - has been used for centuries to take advantage of our body’s tendency to adapt to harsher conditions. As a result, our bodies become more resistant to stress.',
      links: [{title: 'Cold Showers', url: 'https://www.healthline.com/health/cold-shower-benefits', src: 'healthline.com'}]
    }, {
      title: 'Benefits of Saunas',
      desc: 'Saunas can provide many benefits to our mind, body, and soul.  Sitting in a sauna can ease pain, reduce stress, and improve your cardiovascular health.',
      links: [{title: 'Saunas', url: 'https://www.medicalnewstoday.com/articles/313109#possible-health-benefits', src: 'medicalnewstoday.com'}]
    }, {
      title: 'What is your emotional IQ?',
      desc: 'Your Emotional Intelligence matters in building strong relationships and achieving happiness and success.  Your best ideas are only as good as your ability to communicate them, and emotional intelligence can help you achieve your best self.',
      links: [{title: 'Emotional Intelligence', url: 'https://events.6seconds.org/', src: '6seconds.org'}]
    }, {
      title: 'Intermittent Fasting',
      desc: 'Intermittent fasting can help your mind and body in wonderful ways.  Reduce inflamation, allow your body to process cell repair, and live a longer, healthier life!',
      links: [{title: 'Intermittent Fasting', url: 'https://www.health.harvard.edu/blog/intermittent-fasting-surprising-update-2018062914156', src: 'health.harvard.edu'}]
    }, {
      title: 'HeartMath Experience - Free Course!',
      desc: 'Experience a magnificent intelligence you have that is intuitive, gives rise to regenerative emotional qualities, and provides the self-security needed to live a more rewarding life with less stress.',
      links: [{title: 'HeartMath', url: 'https://experience.heartmath.com/course', src: 'heartmath.com'}]
    }]
  })
}
</script>

<style scoped>
.media-button{
  @apply transition duration-500 ease-in-out transform hover:translate-y-1 hover:scale-110 hover:shadow-xl;
}

img {
  display: block;
  max-width:230px;
  max-height:150px;
  width: auto;
  height: auto;
}
</style>