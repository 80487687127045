<template>
  <div class="p-2 rounded-lg bg-gray-100 shadow text-center flex flex-wrap justify-center content-between">
    <div class="text-lg font-bold w-full">{{val.title}}</div>
    <div class="text-left w-full">{{val.desc}}</div>
    <div class="flex flex-col justify-center w-full mt-4">
      <div
        v-for="link in val.links"
        :key="link.title"
        class="mt-1"
      >
        <a
            target="_blank"
            :href="link.url"
            class="p-1 bg-hpp-green-dark rounded-xl text-white hover:bg-hpp-green-light"
          style="display: block"
        >{{link.title}}</a>
        <div class="text-xs text-right" v-if="link.src">via {{link.src}}</div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: "TechniqueBlock",
  props: {
    val: {default: ()=>({title: 'Title', desc: 'Desc', links: []})}
  }
}
</script>

<style scoped>

</style>