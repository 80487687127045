<template>
  <div>
    <!-- 2XL -->
    <div class="hidden 2xl:block">
      <div class="h-96 w-full home-bg fade-in">
        <div class=" grid grid-cols-2">
          <div></div>
          <div class="text-white max-w-32 fade-in-delay-3 mr-8 mt-64 text-6xl shadow-lg p-8 hpp-gradient-green rounded-lg font-bold">
            Human<br>Potential<br>Pathways
          </div>
        </div>
      </div>
      <div class="center-container text-2xl pt-8 fade-in-delay-3">
        <div>Be the <span class="font-bold">best</span> version of yourself.</div>
        <div class="ml-64 text-lg"> ~ Ed Strenk</div>
      </div>
    </div>

    <!-- XL -->
    <div class="hidden xl:block 2xl:hidden">
      <div class="h-96 w-full home-bg home-bg-xl fade-in">
        <div class=" grid grid-cols-2">
          <div></div>
          <div class="text-white max-w-32 fade-in-delay-3 mr-8 mt-56 text-6xl shadow-lg p-8 hpp-gradient-green rounded-lg font-bold">
            Human<br>Potential<br>Pathways
          </div>
        </div>
      </div>
      <div class="center-container text-2xl pt-4 fade-in-delay-3">
        <div>Be the <span class="font-bold">best</span> version of yourself.</div>
        <div class="ml-64 text-lg"> ~ Ed Strenk</div>
      </div>
    </div>

    <!-- LG -->
    <div class="hidden lg:block xl:hidden">
      <div class="h-80 w-full home-bg home-bg-lg fade-in">
        <div class=" grid grid-cols-2">
          <div></div>
          <div class="text-white max-w-32 fade-in-delay-3 mr-8 mt-40 text-6xl shadow-lg p-8 hpp-gradient-green rounded-lg font-bold">
            Human<br>Potential<br>Pathways
          </div>
        </div>
      </div>
      <div class="center-container text-2xl pt-0 fade-in-delay-3">
        <div>Be the <span class="font-bold">best</span> version of yourself.</div>
        <div class="ml-64 text-lg"> ~ Ed Strenk</div>
      </div>
    </div>

    <!-- MD -->
    <div class="hidden md:block lg:hidden">
      <div class="h-80 w-full home-bg home-bg-md fade-in">
        <div class=" grid grid-cols-2">
          <div></div>
          <div class="text-white max-w-32 fade-in-delay-3 mr-8 mt-40 text-6xl shadow-lg p-8 hpp-gradient-green rounded-lg font-bold">
            Human<br>Potential<br>Pathways
          </div>
        </div>
      </div>
      <div class="center-container text-2xl pt-0 fade-in-delay-3">
        <div>Be the <span class="font-bold">best</span> version of yourself.</div>
        <div class="ml-64 text-lg"> ~ Ed Strenk</div>
      </div>
    </div>

    <!-- SM -->
    <div class="md:hidden">
      <div class="h-72 w-full home-bg home-bg-sm fade-in"></div>
      <div
          class="text-white text-center max-w-32 fade-in-delay-3 mx-4 text-2xl shadow-lg p-8 hpp-gradient-green rounded-lg font-bold"
        style="margin-top: -3rem;"
      >
        Human Potential Pathways
      </div>
      <div class="center-container text-center text-xl pt-4 fade-in-delay-3">
        <div>Be the <span class="font-bold">best</span> version of yourself.</div>
        <div class="ml-64 text-lg"> ~ Ed Strenk</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "Splash"
}
</script>

<style scoped>
.home-bg{
  background-image: url("~@/assets/ed_winter_beach.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 0 -120px;
}
.home-bg-xl{
  background-position: 0 -90px;
}
.home-bg-lg{
  background-position: 0 -60px;
}
.home-bg-md{
  background-position: 0px -30px;
}
.home-bg-sm{
  background-position: -90px 0;
}
</style>