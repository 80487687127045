<template>
  <nav class="fixed w-full h-full z-50 transition duration-1000 opacity-0" :class="{'opacity-100' : show}">
    <div
      class="absolute w-full h-full"
      style="background-color: rgba(0,0,0,0.2)"
      @click="close"
    />
    <div class="absolute right-0 h-full w-3/4 bg-white">
      <div class="container mx-auto">
        <div class="text-2xl p-8 text-white font-bold hpp-gradient-green">
          Human Potential Pathways
        </div>
        <div class="p-4">
          <div
              v-for="l in links"
              :key="l.title"
              class="my-6"
          >
            <router-link :to="l.loc">
              <button class="text-2xl w-full" @click="close">{{l.title}}</button>
            </router-link>
          </div>
        </div>

      </div>
    </div>

  </nav>
</template>

<script>
export default {
  name: "MobileNav",
  props: ['show', 'close'],
  data() {
    return {
      links: [
        {title: 'Home', loc: '/'},
        {title: 'Services', loc: 'services'},
        {title: 'Testimonials', loc: 'testimonials'},
        {title: 'FAQ', loc: 'faq'},
        {title: 'Resources', loc: 'resources'},
        {title: 'Tips, Tricks, & Hacks', loc: 'tips'},
        {title: 'Contact Me', loc: 'contact'},
      ]
    }
  },
  methods: {
    toggleNavbar: function(){
      this.showMenu = !this.showMenu;
    }
  }
}
</script>

<style scoped>

</style>