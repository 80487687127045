<template>
  <div class="bg-gray-800 text-white">
    <div class="center-container pb-1 pt-8 mt-0">
      <div class="grid sm:grid-cols-2 text-center sm:text-left">
        <div>
          <div class="font-bold text-sm">Human Potential Pathways</div>
          <div class="italic text-gray-200 text-xl my-4 ml-4">Be the <b>best</b> version of yourself</div>
          <div>Ed Strenk</div>
          <div>edstrenk@gmail.com</div>
          <div>Music © 2022 Matt Bishop Music</div>
        </div>
        <div class="mt-4 sm:mt-0">
          <div class="font-bold">Find me on social media</div>
          <div class="flex justify-center sm:justify-start mt-4">
            <a
                v-for="s in socialMedia"
                :key="s.channel"
                target="_blank"
                :href="s.link"
                class="rounded-full bg-gray-600 max-w-min p-2 mx-2 hover:bg-hpp-green-light transition"
            >
              <s-icon
                size="22"
                :ico="s.channel === 'TikTok' ? 'bx-bxl-tiktok' : 'mdi-'+s.channel.toLocaleLowerCase()"
              />
            </a>
          </div>
        </div>
      </div>
      <div class="text-center text-xs mt-6 text-gray-400">© Human Potential Pathways 2021</div>
    </div>
  </div>
</template>

<script>
import SIcon from "@/components/SIcon";
import socialMedia from "@/assets/socialMedia";

export default {
  name: "HpFooter",
  components: {SIcon},
  data: ()=>({
    socialMedia
  })
}
</script>

<style scoped>

</style>