<template>
  <div class="text-white p-8" :class="[`hpp-gradient-${gradient}`]">
    <div class="container mx-auto max-w-6xl">
      <div class="text-3xl max-w-max pb-2 border-b-2">{{title}}</div>
      <div class="text-gray-200 mt-2">
        <slot/>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ViewHeader",
  props: {
    title: {default: 'About'},
    gradient: {default: 'green'}
  }
}
</script>

<style scoped>

</style>